import { useCallback } from 'react'

import { Tabs } from '@mantine/core'

import { navigate, useParams } from '@redwoodjs/router'

import RulesTab from './tabs/RulesTab/RulesTab'
import TextTemplateTab from './tabs/textTemplateTab/TextTemplateTab'
import VoiceMailTemplateTab from './tabs/voiceMailTemplateTab/VoiceMailTemplateTab'

const SettingsPage = () => {
  const { tab = 'sms' } = useParams()

  const handleTabChange = useCallback((newTab) => {
    navigate(`/settings?tab=${newTab}`)
  }, [])
  return (
    <div className="flex h-full flex-col px-6">
      <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="pills"
        defaultValue="sms"
        className="h-full "
        classNames={{
          panel: 'pt-5 h-[90%]',
          tab: `[&[data-active]]:text-doubleNickel-brand-500 [&[data-active]]:bg-doubleNickel-brand-25 font-semibold text-sm text-doubleNickel-gray-500 `,
        }}
      >
        <Tabs.List className=" border-b-[1px] border-doubleNickel-gray-200 pb-3">
          <Tabs.Tab value="sms">SMS</Tabs.Tab>
          <Tabs.Tab value="rules">Rules</Tabs.Tab>
          <Tabs.Tab value="voicemail">Voicemail</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="sms">
          <TextTemplateTab />
        </Tabs.Panel>
        <Tabs.Panel value="rules">
          <RulesTab />
        </Tabs.Panel>
        <Tabs.Panel value="voicemail">
          <VoiceMailTemplateTab />
        </Tabs.Panel>
      </Tabs>
    </div>
  )
}

export default SettingsPage
