import { useState, useCallback, useEffect } from 'react'

import { ActionIcon, Group, Text, Modal, TextInput } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { VoiceMailTemplateStatus } from 'src/graphql/types/voiceMailTemplate'
import {
  CREATE_VOICEMAIL_TEMPLATE,
  EDIT_VOICEMAIL_TEMPLATE,
} from 'src/graphql/voiceMailTemplate.graphql'
import IconClose from 'src/icons/IconClose'
import IconFile from 'src/icons/IconFile'
import IconUpload from 'src/icons/IconUpload'
import { formatBytes } from 'src/lib/formatters'
const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}
const MEGABYTE = 1024 ** 2

const SelectedFile = ({ file, onClose }) => {
  if (!file) return null
  return (
    <div className="flex flex-row items-center gap-3">
      <IconFile className="h-10 w-8 fill-none stroke-doubleNickel-gray-400" />
      <div className="items-center gap-1.5">
        <Text className="text-md font-medium text-doubleNickel-gray-900">
          {file.name}
        </Text>
        {file.size && (
          <Text className="text-xs text-doubleNickel-gray-500">
            {formatBytes(file.size)}
          </Text>
        )}
      </div>
      <ActionIcon
        variant="subtle"
        className="ml-auto h-6 w-6 rounded-md bg-doubleNickel-white"
        onClick={onClose}
      >
        <IconClose className="h-3 w-3 fill-doubleNickel-white stroke-doubleNickel-gray-500" />
      </ActionIcon>
    </div>
  )
}

const CreateTemplateModal = ({
  voiceMailTemplate,
  isOpen,
  refetchQueries,
  onClose,
  edit = false,
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  //FORM SETUP:
  const schema = z.object({
    title: z
      .string()
      .min(1, { message: 'Please include a title' })
      .max(100, { message: 'Template title is too long' }),
    file: z.object({}),
  })

  const form = useForm({
    initialValues: {
      title: voiceMailTemplate?.title || '',
      file:
        voiceMailTemplate !== undefined
          ? { name: voiceMailTemplate?.title + '.mp3', size: null }
          : null,
    },
    validate: zodResolver(schema),
  })

  useEffect(() => {
    form.setValues({
      title: voiceMailTemplate?.title || '',
      file:
        voiceMailTemplate !== undefined
          ? { name: voiceMailTemplate?.title + '.mp3', size: null }
          : null,
    })
  }, [voiceMailTemplate])

  //MUTATIONS:
  const [updateTemplateStatus] = useMutation(EDIT_VOICEMAIL_TEMPLATE, {
    refetchQueries: refetchQueries,
    onError,
  })

  const [editTemplate] = useMutation(EDIT_VOICEMAIL_TEMPLATE, {
    refetchQueries: refetchQueries,
    onCompleted: async ({ updateVoiceMailTemplate }) => {
      if (form.values.file) {
        const { uploadUrl } = updateVoiceMailTemplate

        await fetch(uploadUrl, {
          method: 'PUT',
          body: form.values.file,
          headers: {
            'Content-Type': 'audio/mpeg',
          },
        })
      }
      setLoadingSubmit(false)
      if (form.values.file) {
        cleanSelectedFile()
      }
      toast('Your voicemail template was updated successfully', 'success')
      onClose()
    },
    onError,
  })

  const [createTemplate] = useMutation(CREATE_VOICEMAIL_TEMPLATE, {
    refetchQueries: refetchQueries,
    onCompleted: async ({ createVoiceMailTemplate }) => {
      const { uploadUrl, voiceMailTemplateId } = createVoiceMailTemplate

      await fetch(uploadUrl, {
        method: 'PUT',
        body: form.values.file,
        headers: {
          'Content-Type': 'audio/mpeg',
        },
      })
      await updateTemplateStatus({
        variables: {
          voiceMailTemplateId,
          input: {
            status: VoiceMailTemplateStatus.ACTIVE,
          },
        },
      })
      setLoadingSubmit(false)
      cleanSelectedFile()
      toast('A new voice mail template was created successfully', 'success')
      onClose()
    },
    onError,
  })

  const onFormSubmit = useCallback(
    (values) => {
      const { file, ...input } = values
      setLoadingSubmit(true)
      edit
        ? editTemplate({
            variables: {
              voiceMailTemplateId: voiceMailTemplate.voiceMailTemplateId,
              input: {
                ...input,
              },
            },
          })
        : createTemplate({
            variables: {
              input: {
                ...input,
              },
            },
          })
    },
    [voiceMailTemplate]
  )

  const onDrop = useCallback(
    (files) => {
      form.setFieldValue('file', files[0])
    },
    [form]
  )

  const cleanSelectedFile = useCallback(() => {
    form.setFieldValue('file', null)
  }, [form])

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={'lg'}
      classNames={{ title: 'text-lg font-bold' }}
      title={edit ? 'Edit Voicemail Template' : 'Create Voicemail Template'}
      centered
    >
      <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
        <div className="flex flex-col gap-6">
          <TextInput
            placeholder={
              form.values.title
                ? (form.values.title as string)
                : 'Voicemail Template # 1'
            }
            label="Template Name"
            {...form.getInputProps('title')}
          />
          {!form.values.file && (
            <div className="flex flex-col gap-1">
              <Dropzone
                accept={['audio/mpeg', 'audio/mp3']}
                maxFiles={1}
                maxSize={5 * MEGABYTE}
                onDrop={onDrop}
                className={form.errors.file && `border-doubleNickel-error-500`}
              >
                <Group className="flex flex-col">
                  <Dropzone.Accept>
                    <p>Drag and drop the file here</p>
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <p>This file type is not supported</p>
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <ActionIcon
                      variant="subtle"
                      className="h-10 w-10 rounded-lg border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm"
                    >
                      <IconUpload className="fill-none stroke-doubleNickel-gray-700" />
                    </ActionIcon>
                  </Dropzone.Idle>
                  <div className="flex flex-col items-center gap-1">
                    <Text className="text-sm text-doubleNickel-gray-600">
                      <span className="font-semibold text-doubleNickel-brand-500">
                        {'Click to upload '}
                      </span>
                      or drag and drop
                    </Text>
                    <Text
                      size="sm"
                      className="text-xs text-doubleNickel-gray-600"
                    >
                      MP3 File (Max 5MB)
                    </Text>
                  </div>
                </Group>
              </Dropzone>
              {form.errors.file && (
                <div className="text-xs text-doubleNickel-error-500">
                  {form.errors.file}
                </div>
              )}
            </div>
          )}

          {form.values.file && (
            <>
              <SelectedFile
                file={form.values.file}
                onClose={cleanSelectedFile}
              />
            </>
          )}
          <Group
            justify="flex-end"
            mt="md"
            className="grid grid-cols-2 gap-4 border-t py-4"
          >
            <Button text="Cancel" variant="outline" onClick={onClose} />
            <Button
              type="submit"
              text={edit ? 'Save' : 'Create'}
              variant="filled"
              loading={loadingSubmit}
            />
          </Group>
        </div>
      </form>
    </Modal>
  )
}

export default CreateTemplateModal
