import { useEffect, useState } from 'react'
import {
  TextInput,
  Drawer,
  Menu,
  ActionIcon,
  Divider,
  Select,
  NumberInput,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useMutation, useQuery } from '@redwoodjs/web'
import Button from 'src/components/Buttons/Button/Button'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  CREATE_AUTOMATED_RULE,
  EDIT_AUTOMATED_RULE,
  GET_AUTOMATED_RULE,
} from 'src/graphql/automatedRules.graphql'
import { GET_JOB_LISTINGS } from 'src/graphql/joblistings.graphql'
import { GET_TEXT_TEMPLATES } from 'src/graphql/textTemplates.graphql'
import IconAdd from 'src/icons/IconAdd'
import {
  fetchConditionOptions,
  ACTIONS_KEYS,
  ADD_CONDITION_KEYS,
  CONDITIONS_KEYS,
  TRIGGER_DELAY_LABELS,
  calculateExecutionLimit,
  FREQUENCY_LABELS,
  convertConditions,
  rulesSchema,
} from '../utils/utils'
import ConditionRow from './ConditionRow'
import IconChevronDown from 'src/icons/IconChevronDown'
import { cn } from 'src/utils'
import IconRightArrow from 'src/icons/IconRightArrow'
import dayjs from 'dayjs'
import { formatSnakeValue } from 'src/lib/formatters'
import { REQUEST_PACKAGES } from 'src/graphql/packages.graphql'
import { GET_DOCUMENT_TEMPLATE_PACKAGES } from 'src/graphql/documentTemplatePackages.graphql'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const RuleDrawer = ({
  selectedRuleId,
  isOpen,
  refetchQueries,
  onClose,
  isEditing = false,
}) => {
  const [formattedConditions, setFormattedConditions] = useState([])
  const [selectedFrequency, setSelectedFrequency] = useState(null)
  const [selectedExecutionLimit, setSelectedExecutionLimit] = useState(null)
  const [selectedTriggerDelay, setSelectedTriggerDelay] = useState(null)
  const [selectedTriggerDelayInt, setSelectedTriggerDelayInt] = useState(null)
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)

  const frequencyOptions = Object.keys(FREQUENCY_LABELS).map((key) => ({
    label: FREQUENCY_LABELS[key],
    value: key,
  }))

  const triggerDelayOptions = Object.keys(TRIGGER_DELAY_LABELS).map((key) => ({
    label: TRIGGER_DELAY_LABELS[key],
    value: key,
  }))

  // QUERIES:
  const { data } = useQuery(GET_AUTOMATED_RULE, {
    variables: {
      automatedRuleId: selectedRuleId,
    },
    skip: !selectedRuleId,
  })

  const { data: jobListingsResponse } = useQuery(GET_JOB_LISTINGS, {
    variables: {
      pageInfo: {
        limit: 100,
        offset: 0,
      },
    },
  })

  const jobListings = jobListingsResponse?.jobListings?.items ?? []
  const selectedRule = data?.automatedRule

  const { data: textTemplatesList } = useQuery(GET_TEXT_TEMPLATES, {
    variables: {
      pageInfo: {
        limit: 100,
        offset: 0,
      },
      orderBy: {
        field: 'title',
        direction: 'asc',
      },
    },
  })

  const { data: documentTemplates } = useQuery(GET_DOCUMENT_TEMPLATE_PACKAGES, {
    onError,
  })

  const packagesList =
    documentTemplates?.documentTemplatePackages?.map(
      (documentTemplatePackage) => ({
        value: documentTemplatePackage.documentTemplatePackageId,
        label: `${formatSnakeValue(documentTemplatePackage.packageName)}`,
        documentTemplatePackage,
      })
    ) || []

  const { data: orders } = useQuery(REQUEST_PACKAGES)
  const orderList =
    orders?.packages?.map((p) => ({
      value: p.packageId,
      label: `${formatSnakeValue(p.packageType)} - ${p.name}`,
      stepTypes: p.stepType,
    })) || []

  // MUTATIONS:
  const [editRule, { loading: editRuleLoading }] = useMutation(
    EDIT_AUTOMATED_RULE,
    {
      refetchQueries: refetchQueries,
      onCompleted: () => {
        toast('Your rule was updated successfully', 'success')
        onClose()
      },
      onError,
    }
  )

  const [createRule, { loading: createRuleLoading }] = useMutation(
    CREATE_AUTOMATED_RULE,
    {
      refetchQueries: refetchQueries,
      onCompleted: () => {
        toast('A new rule was created successfully', 'success')
        onClose()
      },
      onError,
    }
  )

  const conditionsList = selectedRule?.conditions
    ? selectedRule.conditions.map((condition) => ({
        key: condition.key,
        value: condition.value,
        conditionId: condition.conditionId,
      }))
    : [
        {
          key: 'applicantStage',
          value: '',
        },
      ]

  const actionsList = selectedRule?.actions
    ? selectedRule.actions.map((action) => ({
        actionType: action.actionType,
        textTemplateId: action?.textTemplateId || '',
        documentTemplatePackageId: action?.documentTemplatePackageId || '',
        packageId: action?.packageId || '',
      }))
    : [
        {
          actionType: '',
          textTemplateId: '',
          documentTemplatePackageId: '',
          packageId: '',
        },
      ]

  const templatesList = textTemplatesList?.textTemplates?.items ?? []

  const form = useForm({
    initialValues: {
      name: selectedRule?.name || '',
      conditions: conditionsList,
      actions: actionsList,
    },
    validate: zodResolver(rulesSchema),
  })

  useEffect(() => {
    form.setValues({
      name:
        selectedRule?.name ||
        'Automated Rule - ' + dayjs().format('MMMM DD, YYYY'),
      conditions: conditionsList,
      actions: actionsList,
    })
    setSelectedFrequency(selectedRule?.frequency || null)
    setSelectedExecutionLimit(
      selectedRule?.executionLimit
        ? selectedRule.executionLimit.toString()
        : null
    )
    setSelectedTriggerDelay(selectedRule?.triggerDelayType || null)
    setSelectedTriggerDelayInt(selectedRule?.triggerDelay || null)
    setShowAdvancedSettings(
      selectedRule &&
        (selectedRule?.executionLimit ||
          selectedRule?.triggerDelayType ||
          selectedRule?.frequency)
    )
  }, [selectedRule])

  useEffect(() => {
    setFormattedConditions(convertConditions(form.values.conditions))
  }, [form.values.conditions])

  const onRuleSubmit = (values) => {
    // Filter out any subconditions with empty values
    const filteredConditions = values.conditions.filter((condition) => {
      const subCondition = formattedConditions.find(
        (formattedCondition) =>
          formattedCondition.subCondition?.key === condition.key
      )
      return subCondition ? subCondition.subCondition.value !== '' : true
    })

    //Remove attributes if not needed for the selected action
    const currentAction = values.actions.map((action) => {
      if (action.actionType === 'SMS') {
        delete action.documentTemplatePackageId
        delete action.packageId
      }
      if (action.actionType === 'DOCUMENT_TEMPLATE_PACKAGE') {
        delete action.textTemplateId
        delete action.packageId
      }
      if (action.actionType === 'GENERATE_ORDER') {
        delete action.documentTemplatePackageId
        delete action.textTemplateId
      }
      return action
    })

    const payload = {
      name: values.name,
      conditions: filteredConditions,
      actions: currentAction,
      frequency: null,
      frequencyInterval: null,
      executionLimit: null,
      triggerDelayType: null,
      triggerDelay: null,
    }

    if (selectedFrequency) {
      if (selectedFrequency === 'ONCE' && selectedTriggerDelay === null) {
        payload.frequency = null
      } else {
        payload.frequency = selectedFrequency
        payload.executionLimit = parseInt(selectedExecutionLimit)
        payload.triggerDelayType = selectedTriggerDelay
        payload.triggerDelay = selectedTriggerDelayInt
      }
    }

    if (isEditing) {
      editRule({
        variables: {
          automatedRuleId: selectedRule.automatedRuleId,
          input: payload,
        },
      })
    } else {
      createRule({
        variables: {
          input: payload,
        },
      })
    }
  }

  const handleInsert = (condition, value) => {
    //Update the value of the parent condition
    const index = form.values.conditions.findIndex(
      (item) => item.key === condition.key
    )
    form.setFieldValue(`conditions.${index}.value`, value)

    //Remove any existing subcondition
    if (condition.subCondition) {
      const subIndex = form.values.conditions.findIndex(
        (item) => item.key === condition.subCondition.key
      )
      form.removeListItem('conditions', subIndex)
    }
    //Insert a new subcondition
    if (value === 'PROSPECT') {
      form.insertListItem(
        'conditions',
        {
          key: 'hiringStage',
          value: '',
        },
        index + 1
      )
    } else if (value === 'TERMINATED') {
      form.insertListItem(
        'conditions',
        {
          key: 'terminatedReason',
          value: '',
        },
        index + 1
      )
    } else if (value === 'DISQUALIFIED') {
      form.insertListItem(
        'conditions',
        {
          key: 'disqualifiedReason',
          value: '',
        },
        index + 1
      )
    } else if (value === 'NOT_INTERESTED') {
      form.insertListItem(
        'conditions',
        {
          key: 'notInterestedReason',
          value: '',
        },
        index + 1
      )
    }
  }

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      lockScroll={false}
      title={isEditing ? 'Edit Rule' : 'Create Rule'}
      size="35vw"
      classNames={{
        title: 'text-lg font-bold',
        root: 'relative overflow-hidden',
        content: 'bg-doubleNickel-gray-25',
        header: 'bg-doubleNickel-gray-25',
      }}
      position="right"
    >
      <form onSubmit={form.onSubmit((values) => onRuleSubmit(values))}>
        <div className="flex flex-col items-center gap-4 overflow-hidden">
          <TextInput
            placeholder={'Rule Name'}
            label="Rule Name"
            className="w-full"
            {...form.getInputProps('name')}
          />

          <Divider className="w-full" />
          <div className="flex h-[70vh] w-full flex-col gap-4 overflow-auto ">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col text-sm font-medium tracking-wide text-doubleNickel-gray-800">
                Conditions
                <div className="font-normal text-doubleNickel-gray-600">
                  Specify the conditions that will trigger this rule.
                </div>
              </div>
              <div className="flex flex-row items-center justify-between">
                <Menu shadow="md" position="bottom-start">
                  <Menu.Target>
                    <ActionIcon variant="light" color="gray">
                      <IconAdd className="h-4 fill-doubleNickel-white stroke-doubleNickel-gray-600" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown className="w-40">
                    {ADD_CONDITION_KEYS.map((condition) => (
                      <Menu.Item
                        key={condition.value}
                        disabled={form.values.conditions.some(
                          (item) => item.key === condition.value
                        )}
                        onClick={() => {
                          form.insertListItem('conditions', {
                            key: condition.value,
                            value: '',
                          })
                        }}
                      >
                        {condition.label}
                      </Menu.Item>
                    ))}
                  </Menu.Dropdown>
                </Menu>
                {form.errors['conditions'] && (
                  <div className="text-xs text-red-500">
                    {form.errors['conditions']}
                  </div>
                )}
              </div>
            </div>

            {formattedConditions.map((condition, index) => (
              <div key={index} className="flex flex-col items-center gap-2">
                {/********* CONDITIONS *********/}
                <ConditionRow
                  key={condition?.conditionId ?? Math.random()}
                  label={
                    CONDITIONS_KEYS.find((key) => key.value === condition.key)
                      .label
                  }
                  isSubfield={false}
                  showDelete={true}
                  error={
                    form.errors[`conditions.${condition.index}`] ||
                    form.errors[`conditions.${condition.index}.value`]
                  }
                  handleDelete={() =>
                    form.removeListItem('conditions', condition.index)
                  }
                >
                  <Select
                    placeholder="Choose an option"
                    variant="unstyled"
                    data={fetchConditionOptions(condition.key, jobListings)}
                    value={condition.value}
                    onChange={(value) => {
                      handleInsert(condition, value)
                    }}
                  />
                </ConditionRow>
                {/********* SUB-CONDITIONS *********/}
                {condition.subCondition && (
                  <ConditionRow
                    key={condition?.subCondition?.key}
                    label={
                      CONDITIONS_KEYS.find(
                        (key) => key.value === condition.subCondition.key
                      ).label
                    }
                    isSubfield={true}
                    showDelete={false}
                    error={
                      form.errors[
                        `conditions.${condition.subCondition.index}.value`
                      ]
                    }
                  >
                    <Select
                      placeholder="Choose an option"
                      variant="unstyled"
                      data={fetchConditionOptions(condition.subCondition.key)}
                      value={condition.subCondition.value || ''}
                      onChange={(value) => {
                        form.setFieldValue(
                          `conditions.${condition.subCondition.index}.value`,
                          value
                        )
                      }}
                    />
                  </ConditionRow>
                )}
              </div>
            ))}

            <ActionIcon variant="transparent" className="self-center">
              <IconRightArrow className="h-6 rotate-90 fill-none stroke-doubleNickel-gray-500" />
            </ActionIcon>

            <div className="flex flex-col text-sm font-medium tracking-wide text-doubleNickel-gray-800">
              Action
              <div className="font-normal text-doubleNickel-gray-600">
                Define the action that will execute automatically when
                triggered.
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {/*********  ACTIONS *********/}
              <ConditionRow
                label="Action"
                isSubfield={false}
                showDelete={false}
                error={
                  form.errors['actions'] || form.errors['actions.0.actionType']
                }
              >
                <Select
                  placeholder="Choose an option"
                  variant="unstyled"
                  data={ACTIONS_KEYS}
                  value={
                    form.values.actions.length > 0
                      ? form.values.actions[0].actionType
                      : ''
                  }
                  onChange={(value) => {
                    form.setFieldValue(`actions.${0}.actionType`, value)
                  }}
                />
              </ConditionRow>
              {/*********  SUB-ACTIONS *********/}
              {form.values.actions.length > 0 &&
                form.values.actions[0].actionType === 'SMS' && (
                  <ConditionRow
                    label="Template"
                    isSubfield={true}
                    showDelete={false}
                  >
                    <Select
                      placeholder="Choose an option"
                      variant="unstyled"
                      data={templatesList.map((template) => ({
                        label: template.title,
                        value: template.textTemplateId,
                      }))}
                      value={
                        form.values.actions.length > 0
                          ? form.values.actions[0].textTemplateId
                          : ''
                      }
                      error={form.errors['actions.0.textTemplateId']}
                      onChange={(value) => {
                        form.setFieldValue(`actions.${0}.textTemplateId`, value)
                      }}
                    />
                  </ConditionRow>
                )}
              {form.values.actions.length > 0 &&
                form.values.actions[0].actionType ===
                  'DOCUMENT_TEMPLATE_PACKAGE' && (
                  <ConditionRow
                    label="Packages"
                    isSubfield={true}
                    showDelete={false}
                  >
                    <Select
                      placeholder="Choose an option"
                      variant="unstyled"
                      data={packagesList}
                      value={
                        form.values.actions.length > 0
                          ? form.values.actions[0].documentTemplatePackageId
                          : ''
                      }
                      error={form.errors['actions.0.documentTemplatePackageId']}
                      onChange={(value) => {
                        form.setFieldValue(
                          `actions.${0}.documentTemplatePackageId`,
                          value
                        )
                      }}
                    />
                  </ConditionRow>
                )}
              {form.values.actions.length > 0 &&
                form.values.actions[0].actionType === 'GENERATE_ORDER' && (
                  <ConditionRow
                    label="Orders"
                    isSubfield={true}
                    showDelete={false}
                  >
                    <Select
                      placeholder="Choose an option"
                      variant="unstyled"
                      data={orderList}
                      value={
                        form.values.actions.length > 0
                          ? form.values.actions[0].packageId
                          : ''
                      }
                      error={form.errors['actions.0.packageId']}
                      onChange={(value) => {
                        form.setFieldValue(`actions.${0}.packageId`, value)
                      }}
                    />
                  </ConditionRow>
                )}
            </div>
            <ActionIcon variant="transparent" className="self-center">
              <IconRightArrow className="h-6 rotate-90 fill-none stroke-doubleNickel-gray-500" />
            </ActionIcon>
            <div className="flex flex-col text-sm font-medium tracking-wide text-doubleNickel-gray-800">
              Frequency
              <div className="font-normal text-doubleNickel-gray-600">
                Choose how often this rule should run.
                <br /> These fields are optional. The action will run once if
                left blank.
              </div>
            </div>

            <div className="flex flex-col gap-2">
              {/********* FREQUENCY SETTINGS *********/}
              <ConditionRow
                key={'frequency'}
                label={'How often should this action run?'}
                isSubfield={false}
                showDelete={false}
              >
                <Select
                  placeholder="Choose an option"
                  variant="unstyled"
                  data={frequencyOptions}
                  value={selectedFrequency}
                  onChange={(value) => {
                    setSelectedFrequency(value)
                  }}
                />
              </ConditionRow>
              {showAdvancedSettings && selectedFrequency && (
                <>
                  {selectedFrequency !== 'ONCE' && (
                    <ConditionRow
                      key={'maxExecutions'}
                      label={`How many times should this action run?`}
                      isSubfield={false}
                      showDelete={false}
                    >
                      <div className="grid grid-cols-2 items-center gap-2 pt-2">
                        <NumberInput
                          placeholder="Choose a number"
                          min={1}
                          max={calculateExecutionLimit(selectedFrequency)}
                          variant="unstyled"
                          value={selectedExecutionLimit}
                          onChange={(value) => {
                            setSelectedExecutionLimit(value)
                          }}
                        />
                        {selectedFrequency && (
                          <div className="pr-4 text-black">occurences</div>
                        )}
                      </div>
                    </ConditionRow>
                  )}
                  <ConditionRow
                    key={'beginning'}
                    label={'How long should the rule wait before starting?'}
                    isSubfield={false}
                    showDelete={false}
                  >
                    <div className="grid grid-cols-2 gap-2 pt-2">
                      <NumberInput
                        placeholder="Choose a number"
                        min={1}
                        variant="unstyled"
                        value={selectedTriggerDelayInt}
                        onChange={(value) => {
                          setSelectedTriggerDelayInt(value)
                        }}
                      />
                      <Select
                        placeholder="Choose a unit"
                        variant="unstyled"
                        data={triggerDelayOptions}
                        value={selectedTriggerDelay}
                        onChange={(value) => {
                          setSelectedTriggerDelay(value)
                        }}
                      />
                    </div>
                  </ConditionRow>
                </>
              )}

              {selectedFrequency && (
                <div
                  className="flex flex-row items-center gap-2 text-sm font-medium text-doubleNickel-gray-500 hover:cursor-pointer hover:underline"
                  onClick={() => setShowAdvancedSettings((prev) => !prev)}
                >
                  <IconChevronDown
                    className={cn(
                      'h-4  fill-doubleNickel-white stroke-doubleNickel-gray-400',
                      showAdvancedSettings && 'rotate-180'
                    )}
                  />

                  {showAdvancedSettings
                    ? 'Hide advanced settings'
                    : 'Show advanced settings'}
                </div>
              )}
            </div>
          </div>
        </div>

        <Divider className="w-full" classNames={{ root: 'my-4' }} />
        <div className="flex flex-row justify-around gap-4">
          <Button
            className="flex-1"
            text={'Cancel'}
            variant="outline"
            onClick={onClose}
          />
          <Button
            text={'Save'}
            className="flex-1"
            variant="filled"
            type="submit"
            loading={createRuleLoading || editRuleLoading}
          />
        </div>
      </form>
    </Drawer>
  )
}

export default RuleDrawer
