import { ActionIcon, Box, Menu, Switch, Table } from '@mantine/core'

import IconThreeDots from 'src/icons/IconThreeDots'
import { formatDate } from 'src/lib/formatters'

const RuleRow = ({
  element,
  onEdit,
  onStatusChange,
  setSelectedTemplate,
  deleteModalHandlers,
}) => {
  return (
    <Table.Tr
      key={element.automatedRuleId}
      onClick={() => onEdit(element)}
      className="text-sm text-doubleNickel-gray-900"
    >
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>
        {element.createdBy.firstName + ' ' + element.createdBy.lastName}
      </Table.Td>
      <Table.Td>{formatDate(element.createdAt)}</Table.Td>
      <Table.Td>{formatDate(element.updatedAt)}</Table.Td>
      <Table.Td className="flex flex-row justify-end ">
        <Box
          className="flex flex-row items-center gap-2"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Switch
            color="#1C93D7"
            defaultChecked={element.status === 'ACTIVE'}
            onChange={(event) => {
              onStatusChange(element, event.currentTarget.checked)
            }}
          />
          <Menu shadow="md" position="bottom-end">
            <Menu.Target>
              <ActionIcon variant="subtle" className="h-6 w-6">
                <IconThreeDots className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown className="w-40">
              <Menu.Item
                className="text-doubleNickel-gray-700"
                onClick={() => onEdit(element)}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                className="text-doubleNickel-gray-700"
                onClick={() => {
                  setSelectedTemplate(element)
                  deleteModalHandlers.open()
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Table.Td>
    </Table.Tr>
  )
}

export default RuleRow
