import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Loader, Table } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import Empty from 'src/components/Feedback/Empty/Empty'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import DeleteModal from 'src/components/Overlays/DeleteModal/DeleteModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  DELETE_AUTOMATED_RULE,
  GET_AUTOMATED_RULES,
  SET_AUTOMATED_RULE_STATUS,
} from 'src/graphql/automatedRules.graphql'
import IconAdd from 'src/icons/IconAdd'

import RuleDrawer from './components/RuleDrawer'
import RuleRow from './components/RuleRow'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const onDeleteSuccess = () => {
  toast('Your rule was deleted successfully', 'success')
}

const onStatusSuccess = () => {
  toast('Your rule was updated successfully', 'success')
}

const RulesTab = () => {
  const [page, setPage] = useState(1)
  const [selectedRule, setSelectedRule] = useState(null)
  const [editing, setEditing] = useState(false)
  const [ruleDrawerOpened, ruleDrawerHandlers] = useDisclosure(false)
  const [deleteModalOpened, deleteModalHandlers] = useDisclosure(false)
  const itemsPerPage = 10

  //QUERIES:
  const { data: automatedRulesList, loading: automatedRulesLoading } = useQuery(
    GET_AUTOMATED_RULES,
    {
      variables: {
        pageInfo: {
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage,
        },
      },
    }
  )

  //MUTATIONS:
  const [deleteRule] = useMutation(DELETE_AUTOMATED_RULE, {
    refetchQueries: [GET_AUTOMATED_RULES],
    onCompleted: () => {
      onDeleteSuccess()
    },
    onError,
  })

  const [setRuleStatus] = useMutation(SET_AUTOMATED_RULE_STATUS, {
    refetchQueries: [GET_AUTOMATED_RULES],
    onCompleted: () => {
      onStatusSuccess()
    },
    onError,
  })

  //HANDLERS:
  const onEdit = (element) => {
    setSelectedRule(element)
    setEditing(true)
    ruleDrawerHandlers.open()
  }

  const onCreate = () => {
    setSelectedRule(undefined)
    setEditing(false)
    ruleDrawerHandlers.open()
  }

  const onDelete = (element) => {
    setSelectedRule(element)
    deleteRule({
      variables: {
        automatedRuleId: selectedRule.automatedRuleId,
      },
    })
  }

  const onStatusChange = (rule, status) => {
    setRuleStatus({
      variables: {
        automatedRuleId: rule.automatedRuleId,
        status: status ? 'ACTIVE' : 'INACTIVE',
      },
    })
  }

  // This resets the selected template to null when the modal is closed
  //  - Creating a new template sets the selected template to undefined
  //  - Editing a template sets the selected template to the template being edited
  // Note: This distinction is important in order to reset the CreateTemplateModal's form
  useEffect(() => {
    if (!ruleDrawerOpened) {
      setSelectedRule(null)
    }
  }, [ruleDrawerOpened])

  const rules = automatedRulesList?.automatedRules?.items ?? []

  const rows = rules.map((rule) => (
    <RuleRow
      key={rule.automatedRuleId}
      element={rule}
      onEdit={onEdit}
      onStatusChange={onStatusChange}
      setSelectedTemplate={setSelectedRule}
      deleteModalHandlers={deleteModalHandlers}
    />
  ))

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col ">
          <div className="text-lg font-semibold text-doubleNickel-gray-900">
            Rules
          </div>
          <div className="text-sm text-doubleNickel-gray-600">
            {/* These spans are necessary to prevent Google Translate issues  */}
            <span>{automatedRulesList?.automatedRules.totalCount}</span>
            <span> rules</span>
          </div>
        </div>
        <Button
          text="Create Rule"
          lefticon={<IconAdd />}
          onClick={() => onCreate()}
        />
      </div>
      <div className="h-full overflow-auto">
        <Table
          highlightOnHover
          classNames={{
            th: 'h-11 border-none bg-doubleNickel-gray-50 text-xs text-doubleNickel-gray-600',
            td: 'font-medium text-sm text-doubleNickel-gray-900',
          }}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Created By</Table.Th>
              <Table.Th>Created Date</Table.Th>
              <Table.Th>Updated Date</Table.Th>
              <Table.Th className="w-[5%]">Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
          <Table.Tfoot>
            <Table.Tr className="border-none">
              <Table.Td colSpan={5}>
                {automatedRulesLoading ? (
                  <div className="flex flex-row items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-center">
                    {!rules.length && (
                      <Empty
                        title={'No rules found'}
                        subtitle="Add one to get started"
                      />
                    )}
                  </div>
                )}
              </Table.Td>
            </Table.Tr>
          </Table.Tfoot>
        </Table>
      </div>

      <div className="mt-auto">
        <PaginationArea
          page={page}
          totalPages={
            automatedRulesList?.automatedRules.totalCount
              ? Math.ceil(
                  automatedRulesList.automatedRules.totalCount / itemsPerPage
                )
              : 0
          }
          onPageChange={setPage}
        />
      </div>

      {ruleDrawerOpened && (
        <RuleDrawer
          selectedRuleId={selectedRule?.automatedRuleId}
          isEditing={editing}
          isOpen={ruleDrawerOpened}
          onClose={() => ruleDrawerHandlers.close()}
          refetchQueries={[GET_AUTOMATED_RULES]}
        />
      )}
      {deleteModalOpened && (
        <DeleteModal
          title={'Are you sure you want to delete this rule?'}
          opened={deleteModalOpened}
          close={deleteModalHandlers.close}
          onDelete={onDelete}
        />
      )}
    </div>
  )
}

export default RulesTab
