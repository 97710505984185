import { ActionIcon, Select } from '@mantine/core'

import IconCheckmarkCircle from 'src/icons/IconCheckmarkCircle'
import IconTrash from 'src/icons/IconTrash'

const ConditionRow = ({
  label,
  isSubfield = false,
  showDelete,
  handleDelete = () => {},
  children,
  ...props
}) => {
  return (
    <div className="flex w-full flex-row items-center gap-3 ">
      <div className="flex w-full flex-col rounded-lg border bg-white p-2 text-sm font-medium text-doubleNickel-gray-400 shadow">
        {label}
        {children}
      </div>
      <ActionIcon
        variant="subtle"
        className={`${showDelete ? 'visible' : 'invisible'}`}
        onClick={handleDelete}
      >
        <IconTrash className="h-4 fill-doubleNickel-white stroke-doubleNickel-gray-400" />
      </ActionIcon>
    </div>
  )
}

export default ConditionRow
