import { useCallback, useEffect, useState, useRef } from 'react'

import { useMutation } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import { Loader, Table } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import Empty from 'src/components/Feedback/Empty/Empty'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import DeleteModal from 'src/components/Overlays/DeleteModal/DeleteModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_VOICEMAIL_TEMPLATES,
  DELETE_VOICEMAIL_TEMPLATE,
  GET_DOWNLOAD_URL,
} from 'src/graphql/voiceMailTemplate.graphql'
import IconAdd from 'src/icons/IconAdd'

import CreateTemplateModal from './components/CreateTemplateModal'
import TemplateTableRow from './components/TemplateTableRow'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const onSuccess = () => {
  toast('Your text template was deleted successfully', 'success')
}

const VoiceMailTemplateTab = () => {
  const [page, setPage] = useState(1)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [editing, setEditing] = useState(false)
  const [createModalOpened, createModalHandlers] = useDisclosure(false)
  const [deleteModalOpened, deleteModalHandlers] = useDisclosure(false)
  const itemsPerPage = 10
  const [isPlaying, setIsPlaying] = useState(null)
  const audioRef = useRef(null)

  //QUERIES:
  const {
    data: voiceMailTemplatesList,
    loading: voiceMailTemplatesListLoading,
  } = useQuery(GET_VOICEMAIL_TEMPLATES, {
    variables: {
      pageInfo: {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
      },
      orderBy: {
        field: 'title',
        direction: 'asc',
      },
    },
  })

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    onCompleted: (data) => {
      audioRef?.current?.pause()
      const audio = new Audio(data.voiceMailDownloadUrl.url)
      audioRef.current = audio
      audioRef.current.play()
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false)
      })
    },
    onError,
  })

  //MUTATIONS:
  const [deleteTemplate] = useMutation(DELETE_VOICEMAIL_TEMPLATE, {
    refetchQueries: [GET_VOICEMAIL_TEMPLATES],
    onCompleted: () => {
      onSuccess()
    },
    onError,
  })

  //HANDLERS:
  const onEdit = useCallback((element) => {
    setSelectedTemplate(element)
    setEditing(true)
    createModalHandlers.open()
  }, [])

  const onCreate = useCallback(() => {
    setSelectedTemplate(undefined)
    setEditing(false)
    createModalHandlers.open()
  }, [])

  const onDelete = useCallback(() => {
    deleteTemplate({
      variables: {
        voiceMailTemplateId: selectedTemplate.voiceMailTemplateId,
      },
    })
  }, [selectedTemplate])

  const onPlay = useCallback((voiceMailTemplate) => {
    setIsPlaying(voiceMailTemplate.voiceMailTemplateId)
    getDownloadUrl({
      variables: {
        voiceMailTemplateId: voiceMailTemplate.voiceMailTemplateId,
      },
    })
  }, [])

  const onPause = useCallback(() => {
    setIsPlaying(null)
    audioRef?.current?.pause()
  }, [audioRef?.current])

  // This resets the selected template to null when the modal is closed
  //  - Creating a new template sets the selected template to undefined
  //  - Editing a template sets the selected template to the template being edited
  // Note: This distinction is important in order to reset the CreateTemplateModal's form
  useEffect(() => {
    if (!createModalOpened) {
      setSelectedTemplate(null)
    }
  }, [createModalOpened])

  const templates = voiceMailTemplatesList?.voiceMailTemplates?.items ?? []

  const rows = templates.map((element) => (
    <TemplateTableRow
      key={element.voiceMailTemplateId}
      element={element}
      onEdit={onEdit}
      onPlay={onPlay}
      onPause={onPause}
      setSelectedTemplate={setSelectedTemplate}
      deleteModalHandlers={deleteModalHandlers}
      isPlaying={isPlaying}
    />
  ))

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col ">
          <div className="text-lg font-semibold text-doubleNickel-gray-900">
            Voicemail Templates
          </div>
          <div className="text-sm text-doubleNickel-gray-600">
            {/* These spans are necessary to prevent Google Translate issues  */}
            <span>{voiceMailTemplatesList?.voiceMailTemplates.totalCount}</span>
            <span> voicemail templates</span>
          </div>
        </div>
        <Button
          text="Create Template"
          lefticon={<IconAdd />}
          onClick={() => onCreate()}
        />
      </div>
      <div className="h-full overflow-auto">
        <Table
          highlightOnHover
          classNames={{
            th: 'h-11 border-none bg-doubleNickel-gray-50 text-xs text-doubleNickel-gray-600',
            td: 'font-medium text-sm text-doubleNickel-gray-900',
          }}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Template Name</Table.Th>
              <Table.Th>Created By</Table.Th>
              <Table.Th>Created Date</Table.Th>
              <Table.Th>Updated Date</Table.Th>
              <Table.Th className="w-[5%]">Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
          <Table.Tfoot>
            <Table.Tr className="border-none">
              <Table.Td colSpan={5}>
                <span>
                  {voiceMailTemplatesListLoading ? (
                    <div className="flex flex-row items-center justify-center">
                      <Loader />
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-center">
                      {!templates.length && (
                        <Empty
                          title={'No templates found'}
                          subtitle="Add one to get started"
                        />
                      )}
                    </div>
                  )}
                </span>
              </Table.Td>
            </Table.Tr>
          </Table.Tfoot>
        </Table>
      </div>

      <div className="mt-auto">
        <PaginationArea
          page={page}
          totalPages={
            voiceMailTemplatesList?.voiceMailTemplates.totalCount
              ? Math.ceil(
                  voiceMailTemplatesList.voiceMailTemplates.totalCount /
                    itemsPerPage
                )
              : 0
          }
          onPageChange={setPage}
        />
      </div>
      <CreateTemplateModal
        voiceMailTemplate={selectedTemplate}
        edit={editing}
        isOpen={createModalOpened}
        onClose={() => createModalHandlers.close()}
        refetchQueries={[GET_VOICEMAIL_TEMPLATES]}
      />
      <DeleteModal
        title={`Are you sure you want to delete the template ${selectedTemplate?.title}?`}
        opened={deleteModalOpened}
        close={deleteModalHandlers.close}
        onDelete={onDelete}
      />
    </div>
  )
}

export default VoiceMailTemplateTab
