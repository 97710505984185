export const GET_AUTOMATED_RULES = gql`
  query GET_AUTOMATED_RULES($pageInfo: PageInfoInput) {
    automatedRules(pageInfo: $pageInfo) {
      totalCount
      pageInfo {
        offset
        limit
      }
      items {
        automatedRuleId
        name
        createdAt
        updatedAt
        status
        createdById
        createdBy {
          employeeId
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_AUTOMATED_RULE = gql`
  query GET_AUTOMATED_RULE($automatedRuleId: String!) {
    automatedRule(automatedRuleId: $automatedRuleId) {
      automatedRuleId
      name
      createdAt
      updatedAt
      status
      conditions {
        conditionId
        key
        value
      }
      actions {
        actionId
        actionType
        textTemplateId
        documentTemplatePackageId
        packageId
      }
      frequency
      frequencyInterval
      triggerDelayType
      triggerDelay
      executionLimit
      createdById
      createdBy {
        employeeId
        firstName
        lastName
      }
      companyId
    }
  }
`

export const CREATE_AUTOMATED_RULE = gql`
  mutation CREATE_AUTOMATED_RULE($input: AutomatedRuleInput!) {
    createAutomatedRule(input: $input) {
      automatedRuleId
      name
      createdAt
      updatedAt
      status
    }
  }
`

export const EDIT_AUTOMATED_RULE = gql`
  mutation EDIT_AUTOMATED_RULE(
    $automatedRuleId: String!
    $input: AutomatedRuleInput!
  ) {
    updateAutomatedRule(automatedRuleId: $automatedRuleId, input: $input) {
      automatedRuleId
      name
      createdAt
      updatedAt
      status
    }
  }
`

export const SET_AUTOMATED_RULE_STATUS = gql`
  mutation SET_AUTOMATED_RULE_STATUS(
    $automatedRuleId: String!
    $status: AutomatedRuleStatus!
  ) {
    setAutomatedRuleStatus(automatedRuleId: $automatedRuleId, status: $status) {
      automatedRuleId
      name
      createdAt
      updatedAt
      status
    }
  }
`

export const DELETE_AUTOMATED_RULE = gql`
  mutation DELETE_AUTOMATED_RULE($automatedRuleId: String!) {
    deleteAutomatedRule(automatedRuleId: $automatedRuleId) {
      automatedRuleId
      name
      createdAt
      updatedAt
      status
    }
  }
`
