import { ActionIcon, Menu, Table } from '@mantine/core'

import IconPause from 'src/icons/IconPause'
import IconPlay from 'src/icons/IconPlay'
import IconThreeDots from 'src/icons/IconThreeDots'
import { formatDate } from 'src/lib/formatters'

const TemplateTableRow = ({
  element,
  onEdit,
  onPlay,
  onPause,
  setSelectedTemplate,
  deleteModalHandlers,
  isPlaying,
}) => {
  const showPlay = !isPlaying || isPlaying !== element.voiceMailTemplateId

  return (
    <Table.Tr
      key={element.title}
      className="text-sm text-doubleNickel-gray-900"
      onClick={() => onEdit(element)}
    >
      <Table.Td>
        <div className="flex flex-row items-center gap-1">
          <p>{element.title}</p>
          {showPlay && (
            <ActionIcon
              variant="subtle"
              onClick={(e) => {
                e.stopPropagation()
                onPlay(element)
              }}
            >
              <IconPlay className="h-4 fill-none stroke-doubleNickel-brand-500" />
            </ActionIcon>
          )}
          {isPlaying == element.voiceMailTemplateId && (
            <ActionIcon
              variant="subtle"
              onClick={(e) => {
                e.stopPropagation()
                onPause(element)
              }}
            >
              <IconPause className="h-4 fill-none stroke-doubleNickel-brand-500" />
            </ActionIcon>
          )}
        </div>
      </Table.Td>
      <Table.Td>
        {element.createdBy.firstName + ' ' + element.createdBy.lastName}
      </Table.Td>
      <Table.Td>{formatDate(element.createdAt)}</Table.Td>
      <Table.Td>{formatDate(element.updatedAt)}</Table.Td>
      <Table.Td className="flex flex-row justify-end ">
        <Menu shadow="md" position="bottom-end">
          <Menu.Target>
            <ActionIcon
              variant="subtle"
              className="h-6 w-6"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <IconThreeDots className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown className="w-40">
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={(e) => {
                e.stopPropagation()
                onEdit(element)
              }}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedTemplate(element)
                deleteModalHandlers.open()
              }}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  )
}

export default TemplateTableRow
